import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Eventos from "../containers/eventos";

type EventosPageProps = {};

const EventosPage: React.FunctionComponent<EventosPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="Eventos"
        description="Te ofrecemos lo mejor en equipos de audio y luces para tus eventos. Sonido Profesional. Cabinas Dj. Micrófonos, Monitores. Iluminación para Escenario. Luces Beam para fiesta. Iluminación Led para decoración"
      />

      <Eventos />
    </Layout>
  );
};

export default EventosPage;
