import React from "react";
import {
  EventosWrapper,
  EventosTitle,
  EventosSubTitle,
  Instalaciones,
  Equipos,
  About,
  Marcas,
} from "./style";
import Fade from "react-reveal/Fade";
import Video from "./video";
import {
  FaGlassMartiniAlt,
  FaRegBuilding,
  FaBirthdayCake,
  FaRunning,
  FaCameraRetro,
  FaGlassCheers,
  FaWhatsapp,
  FaChevronRight,
} from "react-icons/fa";
import Image from "gatsby-image";
import Lightbox from "./lightbox";
import { useStaticQuery, graphql } from "gatsby";
import Contact from "../bodas/contact";

type EventosProps = {};

const Eventos: React.FunctionComponent<EventosProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      vrx: file(absolutePath: { regex: "/vrx.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 504) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
      allFile(
        sort: { fields: modifiedTime, order: DESC }
        filter: { relativeDirectory: { eq: "marcas" } }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);
  return (
    <EventosWrapper>
      <About>
        <div class="columns">
          <div class="column">
            <div class="description">
              <h1 class="big-title">Dj Paul Luces & Sonido</h1>
              <p>
                Somos la fuente para todas las necesidades de equipos de audio
                para eventos. Personalizamos paquetes de audio y luces para
                eventos corporativos, privados, eventos sociales, activaciones,
                fiestas y matrimonios en todo el país.
              </p>
              <p>
                Años de experiencia nos permiten prever e identificar muchos
                desafíos encontrados al desarrollar un evento. Desde la idea
                inicial hasta el telón final, ofrecemos a nuestros clientes
                consultas colaborativas, compartiendo información valiosa para
                ayudarles a alcanzar sus objetivos con soluciones rentables.
              </p>
              <p>
                Dj Paul Luces & Sonido S.A.C. con RUC 20565731514, te brindará
                el servicio exclusivo para tu evento.
              </p>
              <a
                href="https://bit.ly/385sl0x"
                class="btn"
                rel="noreferrer"
                target="_blank"
              >
                <span>CONSIGUE UNA COTIZACIÓN</span>
                <FaWhatsapp />
              </a>
            </div>
          </div>
          <div class="column">
            <Image fluid={Data.vrx.childImageSharp.fluid} alt="author" />
          </div>
        </div>
      </About>
      <Instalaciones>
        <div class="columns">
          <div class="column">
            <div class="instalaciones">
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaRegBuilding size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Corporativos</h6>
                </div>
              </div>
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaBirthdayCake size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Privados</h6>
                </div>
              </div>
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaGlassMartiniAlt size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Sociales</h6>
                </div>
              </div>
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaCameraRetro size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Activaciones</h6>
                </div>
              </div>
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaRunning size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Deportivos</h6>
                </div>
              </div>
              <div class="instalacion">
                <div class="info">
                  <div class="icon">
                    <FaGlassCheers size="53px" />
                  </div>
                </div>
                <div class="info">
                  <h6 class="title">Fiestas</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="description">
              <h1 class="big-title">nuestros servicios</h1>
              <p>
                Cuando contratas a Dj Paul Luces & Sonido. Nuestro equipo bien
                mantenido incluye lo último y lo más avanzado en iluminación,
                pro audio, consolas, etc. Respaldados y probados por nuestros
                experimentados técnicos.
              </p>
            </div>
          </div>
        </div>
      </Instalaciones>
      <Equipos>
        <div class="container">
          <div class="column">
            <h5 class="title">
              Te ofrecemos lo mejor en equipos de audio y luces para tus
              eventos.
            </h5>
          </div>
          <div class="column">
            <ul class="accordion-list">
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Sonido Profesional
              </li>
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Cabinas Dj
              </li>
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Micrófonos, Monitores...
              </li>
            </ul>
          </div>
          <div class="column">
            <ul class="accordion-list">
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Luces para Escenario
              </li>
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Luces Beam para fiesta
              </li>
              <li>
                <div class="arrow">
                  <FaChevronRight />
                </div>
                Par Led para decoración
              </li>
            </ul>
          </div>
        </div>
      </Equipos>
      <Marcas>
        <div class="columns">
          {Data.allFile.edges.map((file) => (
            <div class="column">
              <Image
                key={file.id}
                fixed={file.node.childImageSharp.fixed}
                alt={file.node.name}
              />
            </div>
          ))}
        </div>
      </Marcas>
      <Fade right>
        <EventosTitle>fotos</EventosTitle>
        <EventosSubTitle>de algunos de nuestros eventos</EventosSubTitle>
      </Fade>
      <Lightbox />
      <Fade right>
        <EventosTitle>cotización</EventosTitle>
        <EventosSubTitle>
          envianos los datos de tu evento para responderte con la proforma
        </EventosSubTitle>
      </Fade>
      <Contact />
    </EventosWrapper>
  );
};

export default Eventos;
