import styled from "styled-components";

export const LightboxWrapper = styled.div`
  clear: both;
  width: 100%;
  padding: 0 75px;
  position: relative;
  height: auto;
  @media (max-width: 600px) {
    padding: 25px 15px;
  }
  .masonry {
    column-count: 4;
    column-gap: 1em;
    margin: 1.5em auto;
    @media (max-width: 1400px) {
      column-count: 4;
    }
    @media (max-width: 1280px) {
      column-count: 3;
    }
    @media (max-width: 770px) {
      column-count: 2;
    }
    @media (max-width: 570px) {
    }
  }

  .masonry-brick {
    background-color: #eee;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
    transition: grid-row-start 300ms linear;
    transition: transform 300ms ease;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  .masonry-brick:hover {
    transform: scale(1.025);
  }
  .gatsby-image-wrapper img {
    display: block;
    border-radius: 4px;
    box-shadow: 0 0 16px #333;
    transition: all 1.5s ease;
    transform: translate3d(0, 0, 0);
  }
  .gatsby-image-wrapper img:hover {
    box-shadow: 0 0 32px #333;
  }
`;
