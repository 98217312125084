import styled from "styled-components";

export const EventosWrapper = styled.div``;

export const EventosTitle = styled.h2``;

export const EventosSubTitle = styled.h3``;

export const About = styled.div`
  padding: 90px 75px;
  @media only screen and (max-width: 992px) {
    padding: 30px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px 0;
  }
  .columns {
    align-items: center;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      display: block;
    }
    .column {
      display: inline-block;
      width: 50%;
      align-items: center;
      @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
      }
    }
  }
  .btn {
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    background-color: transparent;
    padding: 12px 32px 10px;
    font-weight: 700;
    -webkit-transition: 0.35s ease;
    transition: 0.35s ease;
    color: #767676;
    min-width: 140px;
    border: 2px solid #767676;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 16px;
    letter-spacing: 0.1em;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: 600px) {
      padding: 8px 18px;
    }
  }
  .btn:hover {
    color: #fff;
  }
  .btn:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transition: 0.35s ease;
    transition: 0.35s ease;
    background-color: #767676;
    -webkit-transform: translate(-50%, 0) skew(25deg);
    transform: translate(-50%, 0) skew(25deg);
  }
  .btn:hover:before {
    width: 150%;
  }
  .btn span {
    position: relative;
    z-index: 1;
    padding: 10px;
  }

  .description {
    color: #292929;
    margin-left: 16.66666667%;
    @media only screen and (max-width: 600px) {
      margin: 15px;
    }
    .big-title {
      font-size: 5.625em;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: -3.3px;
      margin-bottom: 30px;
      @media only screen and (max-width: 600px) {
        font-size: 40px;
      }
    }
    p {
      color: #767676;
    }
  }
`;

export const Instalaciones = styled.div`
  background-color: #d6c3c7;
  padding: 90px 75px;
  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }
  .columns {
    align-items: center;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 900px) {
      display: block;
    }
    .column {
      display: inline-block;
      width: 50%;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
  .description {
    color: #292929;
    margin-left: 16.66666667%;
    @media only screen and (max-width: 992px) {
      margin: 60px 0;
    }
    @media only screen and (max-width: 600px) {
      margin: 30px 0;
    }
    .big-title {
      font-size: 5.625em;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: -3.3px;
      margin-bottom: 30px;

      @media only screen and (max-width: 600px) {
        font-size: 36px;
      }
    }
    p {
      color: #767676;
    }
  }
  .instalaciones {
    color: #292929;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .instalacion {
    display: inline-block;
    position: relative;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.3333333333%;
    @media only screen and (max-width: 600px) {
      width: 50%;
    }
    .icon {
      background-color: #ffffff;
      color: #292929;
      width: 2.27em;
      height: 2.27em;
      line-height: 2.27em;
      font-size: 3.8em;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      position: relative;
    }
    .info {
      padding: 0.8em 0 0.4em;
      position: relative;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 1.125em;
      font-weight: 700;
      line-height: 1.22;
      margin: 0 1.2em;
    }
  }
`;

export const Equipos = styled.div`
  padding: 90px 75px;
  @media only screen and (max-width: 900px) {
    padding: 30px 0;
  }
  .container {
    align-items: center;
    justify-content: center;
    display: flex;
    @media only screen and (max-width: 600px) {
      display: block;
    }
    .column {
      display: inline-block;
      max-width: 33.333333%;
      align-items: center;
      padding-right: 15px;
      padding-left: 15px;
      @media only screen and (max-width: 600px) {
        display: block;
        max-width: 100%;
      }
    }
  }
  .accordion-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: 500;
      font-size: 16px;
      padding: 10px 0 10px 25px;
    }
  }
  h5 {
    line-height: 45px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -1.3px;
    @media only screen and (max-width: 900px) {
      font-size: 22px;
    }
  }
  .arrow {
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 15px;
    line-height: 25px;
    opacity: 1;
    border-radius: 3px;
    margin: -14px 14px 0 0;
    left: 0;
    text-align: center;
    color: #fff;
    background-color: #292929;
  }
`;

export const Marcas = styled.div`
  background-color: #757575;
  padding: 30px 75px;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
  .columns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .column {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 600px) {
        width: 50%;
      }
    }
  }
`;
